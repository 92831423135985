import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Entrepreneur = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Entrepreneur"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Brainstorm</div>
                    <div className="badgePageItemInfo reverse hidden">Brainstorm at least 5 different business ideas, choose your favourite</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Create</div>
                    <div className="badgePageItemInfo reverse hidden">Create a brand name, logo and colour palette.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Plan.</div>
                    <div className="badgePageItemInfo reverse hidden">Write a basic business plan.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Brand research</div>
                    <div className="badgePageItemInfo reverse hidden">Research your brand name.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Register</div>
                    <div className="badgePageItemInfo reverse hidden">Find out how you would register a limited company with Companies House.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Market research</div>
                    <div className="badgePageItemInfo reverse hidden">Carry out market research.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Training</div>
                    <div className="badgePageItemInfo reverse hidden">Take a class which will help you to deliver your service</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Read</div>
                    <div className="badgePageItemInfo reverse hidden">Read at least one book about entrepreneurship - style guide or biography.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Online presence</div>
                    <div className="badgePageItemInfo refverse hidden">Buy a relevant web domain and claim the social media handles.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
        </div>
    )
}

export default Entrepreneur