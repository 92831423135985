import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';



const CupJul24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1 - Life's a Beach</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 2 - Junkyard Crab</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default CupJul24