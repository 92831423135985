import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Rangoli1 from '../images/globalcitizen-rangoli1.jpg';
import Rangoli2 from '../images/globalcitizen-rangoli2.jpg';
import Rangoli3 from '../images/globalcitizen-rangoli3.jpg';
import Rangoli4 from '../images/globalcitizen-rangoli4.jpg';
import Book1 from '../images/globalcitizen-book1.jpg';

const GlobalCitizen = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Global Citizen"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The concept of agender identity, where individuals do not identify with any specific gender, has likely existed for much of human history, but the terminology and recognition of it as a distinct identity are more recent. </p>
                        <p>One early reference to agender identity can be found in LGBTQ+ communities and literature from the late 20th century. However, pinpointing the exact earliest reference can be challenging due to the evolving nature of language and identity.</p>
                        <br/>
                        <p>The term "agender" itself likely emerged from the broader LGBTQ+ movement, which gained momentum in the latter half of the 20th century. It became more commonly used as language around gender identity became more nuanced and inclusive.</p>
                        <br/>
                        <p>While there might not be a single definitive origin point, the recognition and acknowledgment of agender identity have grown significantly in recent years, particularly within LGBTQ+ communities and academic discussions surrounding gender diversity and inclusivity.</p>
                        <br/>
                        <p>The agender identity has an intriguing history that reflects the evolving understanding of gender:</p>
                        <ol className="list">
                            <li>Early Notions:
                                <ul className="list">
                                    <li>In 1997, the International Journal of Transgenderism acknowledged that individuals of any genetic sex might regard themselves as ungendered, not identifying with any conventional gender.</li>
                                    <li>The 1998 book Working with Lesbian, Gay, Bisexual, and Transgender College Students listed “ungendered” as a label used by some transgender people.</li>
                                    <li>In 2000, a Usenet post described the Christian God as agender, emphasizing a lack of gender.</li>
                                    <li>By 2005, discussions recognized that cultures could include transgender, agender, and hypergender individuals.</li>
                                </ul>
                            </li>
                            <li>Emergence of the Term:
                                <ul className="list">
                                    <li>The term “agender” gained prominence in the mid-2010s.</li>
                                    <li>In 2014, Salem X (also known as “Ska”) created the agender flag, featuring black and white stripes representing absence of gender, gray for semi-genderlessness, and a central green stripe for nonbinary genders.</li>
                                    <li>Facebook introduced agender as one of the 56 gender options in the same year.</li>
                                    <li>Dictionary.com officially defined “agender” in 2015 as a person without a specific gender identity or recognizable gender expression.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I have read Finding Nevo.</p>
                        <img className='imgThird' src={Book1} alt="Screenshot of the cover of a book titled Finding Nevo" />
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Agender Pride Day started on 2017 and is celebrated annually on May 19th to recognise and celebrate people who identify as agender.</p>
                        <p>Agender Pride Day is about acknowledging, respecting and celebrating people who don't identify as a specific gender, reinforcing that gender identity isn't confined to traditional labels.</p>
                        <p>Agender Pride Day also highlights the hate, discrimination and injustices that agender people face and tries to raise awareness about this to move towards a more accepting society.</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I made a rangoli, these are used during Diwali.</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Rangoli1} alt="Rangoli stencil and packets of coloured sand."/></div>
                            <div className="galleryItem"><img src={Rangoli2} alt="Rangoli artwork with coloured sand added to a stencil."/></div>
                            <div className="galleryItem"><img src={Rangoli3} alt="Completed Rangoli art with the stencil still in place."/></div>
                            <div className="galleryItem"><img src={Rangoli4} alt="Completed Rangoli art with the stencil removed."/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have been looking at Hearing Impairment for this clause. This is a summary, not representative of the full scale of research!</p>
                        <br/>
                        <p>Types of Hearing Loss:</p>
                        <ol className="list">
                            <li>Conductive Hearing Loss:
                                <ul className="list">
                                    <li>Caused by issues in the outer or middle ear.</li>
                                    <li>Often treatable with medicine or surgery.</li>
                                </ul>
                            </li>
                            <li>Sensorineural Hearing Loss:
                                <ul className="list">
                                    <li>Results from inner ear or hearing nerve problems.</li>
                                    <li>Generally irreversible.</li>
                                </ul>
                            </li>
                            <li>Mixed Hearing Loss:
                                <ul className="list">
                                    <li>Combination of both conductive and sensorineural hearing loss.</li>
                                </ul>
                            </li>
                            <li>Auditory Neuropathy Spectrum Disorder:
                                <ul className="list">
                                    <li>Sound enters the ear normally, but the brain struggles to interpret it due to inner ear or nerve damage.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>Hearing impairment can significantly impact people throughout all stages of development, including:</p>
                        <ul className="list">
                            <li>Difficulty expressing hearing limitations.</li>
                            <li>Delayed vocabulary development.</li>
                            <li>Challenges in understanding speech.</li>
                            <li>Potential self-confidence issues.</li>
                            <li>Embarrassment about mishearing conversations.</li>
                            <li>Reduced social interactions and loneliness.</li>
                            <li>Lowered self-confidence.</li>
                            <li>Increased risk of cognitive decline and dementia.</li>
                        </ul>
                        <br/>
                        <p>Globally, hearing impairment costs around $750 billion per year, with effects on productivity due to impaired communication and imapct on education and social services.</p>
                        <br/><br/>
                        <p>There are various ways in which hearing impaired people communicate, and this will vary from person to person. These include:</p>
                        <ol className="list">
                            <li>Listening and Speaking (Oralism):
                                <ul className="list">
                                    <li>Some deaf people communicate using spoken language.</li>
                                    <li>They may use hearing aids or cochlear implants to enhance their ability to hear and speak.</li>
                                </ul>
                            </li>
                            <li>Lip Reading (Speechreading):
                                <ul className="list">
                                    <li>Lip reading involves interpreting speech by observing lip patterns.</li>
                                    <li>While natural, it’s challenging to rely solely on lip reading due to similar lip shapes for different sounds.</li>
                                </ul>
                            </li>
                            <li>Sign Language:
                                <ul className="list">
                                    <li>British Sign Language (BSL): The main sign language in the British Deaf community. </li>
                                    <li>Has its own vocabulary, grammar, history, and culture.</li>
                                    <li>Recognized as a language under UK law.</li>
                                    <li>Irish Sign Language (ISL): Used in Ireland and by some in Northern Ireland. </li>
                                    <li>Different from BSL, with its own vocabulary and structure.</li>
                                </ul>
                            </li>
                            <li>Fingerspelling:
                                <ul className="list">
                                    <li>British fingerspelling alphabet spells out words using handshapes and fingers.</li>
                                    <li>A stepping stone toward learning BSL.</li>
                                </ul>
                            </li>
                            <li>Sign Supported English (SSE):
                                <ul className="list">
                                    <li>Combines BSL signs with English word order.</li>
                                    <li>Useful for deaf individuals who use both speech and BSL.</li>
                                </ul>
                            </li>
                            <li>Sign Systems (e.g., Makaton, Signalong):
                                <ul className="list">
                                    <li>Designed for people with speech and language difficulties or learning disabilities.</li>
                                    <li>Use signs and symbols alongside speech.</li>
                                </ul>
                            </li>
                            <li>Tactile Signing (Hands-On Signing):
                                <ul className="list">
                                    <li>For people who are blind or visually impaired.</li>
                                    <li>Allows them to feel the signs made by another person.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>There are ways in which hearing people can support people with hearing impairments. It’s important to understand the challenges faced and the solutions available. These are some of the ways to provide support:</p>
                        <ol className="list">
                            <li>Communication Considerations:
                                <ul className="list">
                                    <li>Ask: Inquire about the preferred communication method (e.g., sign language, lip reading, written communication).</li>
                                    <li>Be Patient: Give extra time for responses, especially during conversations.</li>
                                    <li>Face the Person: Ensure your face is visible for lip reading.</li>
                                    <li>Speak Clearly: Avoid shouting; speak at a moderate pace and enunciate.</li>
                                    <li>Rephrase: If misunderstood, rephrase rather than repeating the same words.</li>
                                </ul>
                            </li>
                            <li>Technological Support:
                                <ul className="list">
                                    <li>Assistive Listening Devices: Recommend devices like hearing loops or amplified telephones.</li>
                                    <li>Cochlear Implants: Understand how these work and their benefits.</li>
                                    <li>Online Services: Encourage using email, SMS, or text relay for communication.</li>
                                </ul>
                            </li>
                            <li>Social Interaction:
                                <ul className="list">
                                    <li>Include Them: Invite them to social events and gatherings.</li>
                                    <li>Choose Quiet Settings: Opt for quieter environments to facilitate conversation.</li>
                                    <li>Be Mindful: Avoid interrupting or talking over others.</li>
                                </ul>
                            </li>
                            <li>Accessibility Awareness:
                                <ul className="list">
                                    <li>Subtitles and Captions: Use them in videos and online content.</li>
                                    <li>Plain English: Simplify written information for better understanding.</li>
                                    <li>BSL Translations: Provide British Sign Language (BSL) video translations when possible.</li>
                                </ul>
                            </li>
                            <li>5.	Empathy and Respect:
                                <ul className="list">
                                    <li>Listen Actively: Show genuine interest and empathy.</li>
                                    <li>Avoid Stigmatization: Treat them as equals; don’t make assumptions.</li>
                                    <li>Advocate: Speak up for their needs when appropriate.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>Shared experiences, language and history have created a vibrant deaf culture, these are some of the aspects of deaf community and culture:</p>
                        <ol className="list">
                            <li>Unique Culture and Language:
                                <ul className="list">
                                    <li>Like any linguistic minority, Deaf people have their own unique culture.</li>
                                    <li>British Sign Language (BSL) is central to this culture, with its own vocabulary, grammar, and expressive power.</li>
                                    <li>Deaf culture encompasses beliefs, attitudes, norms, values, and literary traditions shared by Deaf individuals.</li>
                                </ul>
                            </li>
                            <li>Visual Communication:
                                <ul className="list">
                                    <li>Deaf people often communicate visually, relying on eye contact and facial expressions.</li>
                                    <li>In restaurants, for example, constant eye contact is maintained during conversations in Sign Language.</li>
                                    <li>This visual communication style differs from the way hearing people interact.</li>
                                </ul>
                            </li>
                            <li>Storytelling and Tradition:
                                <ul className="list">
                                   <li>Within the Deaf community, there’s a strong tradition of storytelling and joke-telling.</li>
                                   <li>Stories are passed down from one generation to the next, often expressed with great dignity.</li>
                                   <li>Deaf individuals take pride in their history and the contributions of Deaf achievers to both Deaf and hearing society.</li>
                                </ul>
                            </li>
                            <li>Art and Expression:
                                <ul className="list">
                                   <li>BSL flourishes in various art forms, including drama, poetry, comedy, and satire.</li>
                                   <li>Recent productions tackle themes at the heart of Deaf experience.</li>
                                   <li>Deaf culture celebrates creativity and expression.</li>
                                </ul>
                            </li>
                            <li>Community and Belonging:
                                <ul className="list">
                                   <li>The Deaf community provides a sense of belonging.</li>
                                   <li>Deaf clubs, social events, and organizations allow Deaf people to share experiences, exchange information, and build lasting connections.</li>
                                </ul>
                            </li>
                            <li>Challenges and Strengths:
                                <ul className="list">
                                   <li>Deaf culture challenges the medical view of deafness as a condition to be “treated.”</li>
                                   <li>Hearing individuals often discover the strength and value of Deaf culture when they encounter it firsthand.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p><strong>Some information about cochlear implants</strong></p>
                        <p>A cochlear implant is a small electronic medical device designed to improve moderate to severe hearing loss. It serves both adults and children, including babies. Here’s how it works:</p>
                        <ol className="list">
                            <li>External Component:
                                <ul className="list">
                                    <li>Placed behind the ear.</li>
                                    <li>Includes a microphone that receives sound waves.</li>
                                    <li>A speech processor analyzes sounds and converts them into digital signals.</li>
                                    <li>These signals are transmitted to an internal receiver via a transmitter.</li>
                                    <li>The transmitter and receiver are held together by a magnet.</li>
                                </ul>
                            </li>
                            <li>Internal Portion:
                                <ul className="list">
                                    <li>Implanted beneath the skin behind the ear.</li>
                                    <li>The receiver converts digital signals into electrical impulses.</li>
                                    <li>These impulses stimulate electrodes in the cochlea (the spiral-shaped organ in the inner ear).</li>
                                    <li>The cochlear nerve then relays these signals to the brain, creating a sense of hearing.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>These aren’t a “cure” and aren’t embraced by every deaf person. Here are some pros and cons of cochlear implants:</p>
                        <br/>
                        <p><strong>Pros of Cochlear Implants:</strong></p>
                        <ol className="list">
                            <li>Improved Quality of Life:
                                <ul className="list">
                                    <li>For severe hearing loss, implants can significantly enhance daily life.</li>
                                    <li>Benefits vary based on the procedure and rehabilitation process.</li>
                                </ul>
                            </li>
                            <li>Hearing Different Sounds:
                                <ul className="list">
                                   <li>Cochlear implants allow users to hear various sounds, including footsteps, voices on the phone, and music.</li>
                                </ul>   
                            </li>
                            <li>Speech Comprehension:
                                <ul className="list">
                                   <li>Users can understand speech without relying solely on lip reading.</li>
                                </ul>
                            </li>
                            <li>Enhanced Learning for Children:
                                <ul className="list">
                                   <li>Implants can help babies and toddlers learn how to talk.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/>
                        <p><strong>Cons of Cochlear Implants:</strong></p>
                        <ol className="list">
                            <li>Surgery Risks:
                                <ul className="list">
                                    <li>Cochlear implant surgery is generally safe but carries inherent risks.</li>
                                    <li>These include infection, facial nerve paralysis (rare but serious), and device failure.</li>
                                </ul>
                            </li>
                            <li>Loss of Residual Hearing:
                                <ul className="list">
                                    <li>Some individuals may lose any remaining natural hearing in the implanted ear.</li>
                                </ul>
                            </li>
                            <li>Extensive Therapy and Training:
                                <ul className="list">
                                    <li>Successful use of implants requires ongoing therapy and training.</li>
                                    <li>Learning to interpret the new sounds takes time and effort.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p><strong>Some more information about lip reading</strong></p>
                        <p>Also known as “speechreading”, lip readers observe the lip movements and facial expressions of a speaker to determine what they are saying.</p>
                        <p>Here’s a beginner’s guide:</p>
                        <ol className="list">
                            <li>Understanding Lip Reading:
                                <ul className='list'>
                                    <li>Lip reading allows you to “listen” to a speaker by watching their face to decipher speech patterns, movements, gestures, and expressions.</li>
                                    <li>It goes beyond merely reading the lips to understand individual words.</li>
                                    <li>Lip readers use visual cues from the speaker’s mouth, teeth, and tongue.</li>
                                </ul>
                            </li>
                            <li>Skills Involved in Lip Reading:
                                <ul className='list'>
                                    <li>Visual Cues: Pay attention to the movements of the speaker’s mouth, teeth, and tongue.</li>
                                    <li>Facial Expressions and Body Language: Evaluate information provided by facial expressions and gestures alongside spoken words.</li>
                                    <li>Vision Assistance: Use vision to complement listening.</li>
                                    <li>Prior Knowledge: Fill in gaps in understanding, as it’s impossible to read every word spoken. Interestingly, longer words and sentences are often easier to lip read than shorter ones.</li>
                                </ul>
                            </li>
                            <li>Interesting Facts about Lip Reading:
                                <ul className='list'>
                                    <li>Easier in quiet environments with fewer distractions.</li>
                                    <li>Some people mumble or have difficulties speaking, making them challenging to read.</li>
                                    <li>Good eyesight is essential for effective lip reading.</li>
                                    <li>Adequate lighting is crucial; lip reading in the dark is impossible.</li>
                                    <li>Lip reading is more successful in the lip reader’s first language.</li>
                                    <li>Similar lip shapes (e.g., “th” and “f”) can be harder to decipher, emphasizing the importance of subtle facial cues.</li>
                                </ul>
                            </li>
                            <li>Benefits of Lip Reading:
                                <ul className='list'>
                                    <li>Improved Communication: Lip reading opens up the world for individuals with hearing loss, enhancing confidence and social skills.</li>
                                    <li>Empathy: Family and friends who learn to lip read gain insight into the challenges faced by their loved ones.</li>
                                    <li>Professional Use: Communication professionals can benefit from lip reading to interact effectively with deaf and hard-of-hearing individuals in various settings.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>There are some challenges to lip reading, these are some of them:</p>
                        <ol className="list">
                            <li>Variations in Lip Shapes and Movements:
                                <ul className="list">
                                    <li>Different people have unique lip shapes and movements.</li>
                                    <li>Regional accents and speaking speeds can present challenges for lip readers.</li>
                                </ul>
                            </li>
                            <li>Individual Speaking Habits:
                                <ul className="list">
                                    <li>Some individuals mumble or have difficulties speaking clearly.</li>
                                    <li>These variations make it practically impossible to read their lips effectively.</li>
                                </ul>
                            </li>
                            <li>Visual Limitations:
                                <ul className="list">
                                    <li>Good Eyesight Required: Lip reading relies heavily on visual cues.</li>
                                    <li>Adequate Lighting: Reading lips in the dark is nearly impossible.</li>
                                </ul>
                            </li>
                            <li>Contextual Understanding:
                                <ul className="list">
                                    <li>Lip readers often fill in gaps using prior knowledge.</li>
                                    <li>Reading every spoken word accurately can be challenging.</li>
                                </ul>
                            </li>
                            <li>Speech Speed and Rhythms:
                                <ul className="list">
                                    <li>Rapid speech can make lip reading more demanding.</li>
                                    <li>Longer words and complete sentences are often easier to interpret than short phrases.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I am taking a sign language class with Centre of Excellence.</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default GlobalCitizen